import React from "react";
import fetch from 'cross-fetch';
import {objToURI} from '../../helpers/converter'
import { withTranslation } from "react-i18next";

class Netlifyform extends React.Component {

    formName = ""
    subject = ""
    state = {}

    constructor(props) {
        super(props)
        this.trans = props.t
    }

    encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join('&');
    }
    
    validateForm() {
        return true
    }

    onFormSubmit = event => {
        event.preventDefault();

        fetch(event.target.action, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: this.encode({
                'form-name': this.formName,
                'subject': this.subject,
                ...this.state,
            }),
        })
        .then(res => res.json())
        .then(response => {
            if (this.onSuccess != null) {
                this.onSuccess(response)
            }
        })
        .catch(error => {
            if (this.onError != null) {
                this.onError(error)
            }
        })
    }

    render() {

        this.state = this.props.state
        this.formName = this.props.name
        this.onSuccess = this.props.onSuccess || null
        this.onError = this.props.onSuccess || null
        const className = this.props.className || ""
        const action = this.props.action || "/success"
        this.subject = this.props.subject || false

        return (
            <form onSubmit={this.onFormSubmit} className={className} name={this.formName} method="POST" netlify-honeypot="age" data-netlify="true" action={action}>
                {this.props.children}
                <input type="hidden" name="form-name" value={this.formName} />
                {this.subject !== false &&
                    <input type="hidden" name="subject" value={this.subject} />
                }
            </form>
        )
    }
}

export default withTranslation()(Netlifyform)